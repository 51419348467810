export default {
  methods: {
    getPrefixReglement(value) {
      return value.split('_').slice(0, 2).join('_')
    },
    getReglementFromEPCI(zone_urba, nomfic, pieces_ecrites) {
      let docs_from_EPCI = []
      if (this.isExist(zone_urba.code_epci)) {
        for (let [key, value] of Object.entries(pieces_ecrites)) {
          if (
            this.getPrefixReglement(nomfic) === this.getPrefixReglement(key) &&
            key.split('_').length < 4
          ) {
            docs_from_EPCI.push({
              name: nomfic.split('_')[1],
              date: this.getDatePiece(nomfic),
              url: value,
            })
            delete pieces_ecrites[key]
          }
        }
      }
      return docs_from_EPCI
    },
    getDocsUrbanisme(informations, zone_urba) {
      let nomfic = this.getNestedObject(zone_urba, 'properties', 'nomfic')
      let docs = []
      let reglement_urba = informations.pieces_ecrites
      Object.keys(reglement_urba).forEach((reglement) => {
        docs.push({
          name: reglement,
          date: this.getDatePiece(reglement),
          url: reglement_urba[reglement],
        })
      })

      let pieces_ecrites = this.copyObject(informations.pieces_ecrites)
      docs.push(...this.getReglementFromEPCI(zone_urba, nomfic, pieces_ecrites))

      for (let [key, value] of Object.entries(pieces_ecrites)) {
        if (
          (nomfic !== key && key.split('_')[0] === zone_urba.code_insee) ||
          this.isExist(zone_urba.code_epci)
        ) {
          let key_doc = key.split('_')
          let name_doc = key_doc.slice(1, key_doc.length - 1).join(' ')
          docs.push({
            name: name_doc,
            date: this.getDatePiece(key),
            url: value,
          })
        } else if (key.split('_')[0] !== zone_urba.code_insee) {
          let key_doc = key.split('-')

          let name_doc = key_doc.slice(1, key_doc.length).join(' ')
          if (name_doc) {
            docs.push({
              name: name_doc,
              date: this.getDateFormat(zone_urba.properties.datvalid),
              url: value,
            })
          }

        }
      }
      return docs
    },

    addMarkersZones(libelles_zones_urbanisme, union_parcelles) {
      let center = this.$turf.pointOnFeature(union_parcelles)

      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
      })
      let template = this.templatePopup(
        ['Zone(s)'],
        libelles_zones_urbanisme,
        true,
      )

      var el = document.createElement('div')
      el.className = 'mapbox-marker'
      el.style.backgroundImage = `url(/images/map/marker.svg)`

      let popup_marker = this.addCustomMarkerWithPopupInMap(
        template,
        center.geometry.coordinates,
        el,
        popup,
      )
      popup_marker.popup.on('open', (e) => {
        document
          .querySelector('#popup-marker')
          .addEventListener('click', this.setShowModal)
        this.customPopupButtonClose(e)
      })
      popup_marker.marker.togglePopup()
      return popup_marker.marker
    },
    setShowModal() {
      this.showModal = true
    },

    getDateFormat(temp_date) {
      if (!this.isExist(temp_date)) return ''
      let year = temp_date.substring(0, 4)
      let mounth = temp_date.substring(4, 6)
      let day = temp_date.substring(6, 8)
      let date = day + '/' + mounth + '/' + year
      return date
    },
    getDatePiece(chaine) {
      var pieces = chaine.split('_')
      let temp_date = pieces[pieces.length - 1].split('.')[0]
      return this.getDateFormat(temp_date)
    },
  },
}
