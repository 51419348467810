<template>
  <div class="w-full h-full">
    <Popup v-show="showModal" @close="showModal = false">
      <h2 class="text-2xl sm:text-lg font-bold py-2 font-main" slot="title">
        Téléchargez les documents
      </h2>
      <template #body>
        <div class="flex flex-col space-y-6">
          <div
            class="font-main font-semibold text-base capitalize bg-promy-gray-50 text-promy-green-350 py-3 px-3 rounded-md"
          >
            <span class="cursor-pointer" @click="selectAllUrbaDocs">
              sélectionnez tout
              {{
                !isSelectedDocsUrbanisme
                  ? `(${selected_urbanisme_docs.length})`
                  : '(0)'
              }}
            </span>
          </div>
          <div
            class="overflow-auto max-h-72 sm:max-h-40 pr-5 scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100"
            id="urba_docs_content"
          >
            <div
              class="flex flex-col"
              v-for="(doc, index) in docs_urbanisme"
              :key="index"
            >
              <div class="flex justify-between">
                <div class="flex space-x-2">
                  <ProCheckbox
                    staticLabel
                    :val="doc"
                    :id="`checkbox_${doc.url}`"
                    v-model="selected_urbanisme_docs"
                  />
                  <a
                    class="text-promy-green-350 cursor-pointer border-promy-green-350 border-b"
                    @click="
                      ;(current_link_pdf = doc.url), (show_modal_pdf = true)
                    "
                  >
                    {{ doc.name }}
                  </a>
                </div>
                <span class="text-promy-gray-250">{{ doc.date }}</span>
              </div>
              <hr class="mt-3 text-promy-gray-250" />
            </div>
          </div>
        </div>
        <ProButton
          :disabled="isDownloading"
          class="h-[60px] space-x-1 w-[13rem] capitalize !mt-20"
          @click="getZipFile"
        >
          <span>{{ isDownloading ? 'téléchargement' : 'téléchargez' }} </span>
          <Spinner v-if="isDownloading" />
        </ProButton>
      </template>
    </Popup>
    <PopupPdf
      class="z-[1024]"
      :url="current_link_pdf"
      @closeModalPDF="show_modal_pdf = false"
      :show_modal_pdf="show_modal_pdf"
    >
    </PopupPdf>
    <div class="w-full height-container-mapbox">
      <div id="mapbox"></div>
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import urbanisme from '@/mixins/urbanisme'

export default {
  mixins: [Mapbox, parcelle, urbanisme],
  data() {
    return {
      feature_batis_3d_exist: [],
      markers_zone: [],
      showModal: false,
      docs_urbanisme: [],
      current_link_pdf: null,
      show_modal_pdf: false,
      markers_libelle_zone: [],
      selected_urbanisme_docs: [],
      isDownloading: false,
    }
  },
  props: {
    zones_urbanisme: {
      type: Array,
      required: true,
    },
    libelles_zones_urbanisme: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
      infos_urbanisme: 'address/infos_urbanisme',
    }),
    isSelectedDocsUrbanisme() {
      return _.isEmpty(this.selected_urbanisme_docs)
    },
  },
  created() {
    if (this.zones_urbanisme.length) {
      this.setDocsUrbanisme()
    }
  },
  mounted() {
    let is_map_loaded = this.loadMap()
    if (this.zones_urbanisme.length) {
      is_map_loaded
        ? this.loadDataToMap()
        : this.map.on('load', this.loadDataToMap)
    }
  },
  methods: {
    getValuesAtLastLevel(obj) {
      const values = []

      function traverse(obj) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key]

            if (
              !Array.isArray(value) &&
              typeof value === 'object' &&
              value !== null
            ) {
              traverse(value)
            } else {
              value.forEach((item) => {
                item.category = key
              })
              values.push(value)
            }
          }
        }
      }

      traverse(obj)
      return values
    },
    setDocsUrbanisme() {
      let sources = ['epci', 'commune']
      sources.forEach((source) => {
        if (
          this.getValuesAtLastLevel(
            this.infos_urbanisme.data.pieces_ecrites[source],
          ).flat().length
        ) {
          this.docs_urbanisme = [
            ...this.docs_urbanisme,
            ...this.getValuesAtLastLevel(
              this.infos_urbanisme.data.pieces_ecrites[source],
            ).flat(),
          ]
        }
      })
    },
    loadDataToMap() {
      let fc_own_parcelles = this.$turf.featureCollection(this.own_parcelles)
      let fc_zones_urbanisme = this.$turf.featureCollection(
        this.zones_urbanisme,
      )
      let featureCollection = fc_zones_urbanisme
        ? fc_zones_urbanisme
        : fc_own_parcelles
      this.fitBoundsGeojsonInMap(featureCollection)
      this.addLayersUrbanisme()
      this.addSourceLayersMultiGeojsonToMap(
        this.own_parcelles,
        this.green_parcelle_selected,
        this.source_layers_all_parcelle,
      )
    },
    getExpressionEqualTypeZone(type) {
      return ['==', ['get', 'typezone'], type]
    },
    addLayersUrbanisme() {
      let union_own_parcelles = this.$turf.union(...this.own_parcelles)
      this.markers_zone.push(
        this.addMarkersZones(
          this.libelles_zones_urbanisme,
          union_own_parcelles,
        ),
      )

      this.addSourceLayersMultiGeojsonToMap(
        this.zones_urbanisme,
        this.purple,
        this.source_and_layers_urbanisme,
      )
      this.map.setPaintProperty(
        this.source_and_layers_urbanisme[1],
        'fill-color',
        [
          'case',
          this.getExpressionEqualTypeZone('N'),
          '#28BE40',
          this.getExpressionEqualTypeZone('A'),
          '#F1FF52',
          this.getExpressionEqualTypeZone('AU'),
          '#AF5417',
          this.getExpressionEqualTypeZone('U'),
          '#FF7A00',
          '#1D63EB',
        ],
      )
      this.map.addSource(this.source_and_layers_libelle_urbanisme_autour[0], {
        type: 'geojson',
        data: this.$turf.featureCollection(this.zones_urbanisme),
      })

      this.map.addLayer({
        id: this.source_and_layers_libelle_urbanisme_autour[1],
        type: 'symbol',
        source: this.source_and_layers_libelle_urbanisme_autour[0],
        layout: {
          'text-field': ['get', 'libelle'],
          'text-justify': 'auto',
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#fff',
          'text-halo-width': 0.7,
        },
      })
    },
    addLibelleZoneOnMap(geojson, libelle) {
      var pointOnPolygon = this.$turf.pointOnFeature(
        this.$turf.buffer(geojson, -0.0005),
      )
      var el = document.createElement('p')
      el.className = 'libelle-zone'
      el.innerHTML = libelle
      this.markers_libelle_zone.push(
        new this.$mapboxgl.Marker(el)
          .setLngLat(pointOnPolygon.geometry.coordinates)
          .addTo(this.map),
      )
    },
    selectAllUrbaDocs() {
      this.selected_urbanisme_docs =
        this.selected_urbanisme_docs.length === this.docs_urbanisme.length
          ? []
          : this.docs_urbanisme
    },
    async getZipFile() {
      if (this.isSelectedDocsUrbanisme) {
        this.toast(
          'Document Urbanisme',
          'Veuillez choisir des fichiers dans la liste',
          'warning',
        )
        return
      }
      this.isDownloading = true
      this.$http
        .post(
          'grand-publics/urbanisme/download',
          {
            documents: this.selected_urbanisme_docs,
          },
          {
            responseType: 'arraybuffer',
          },
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: 'application/zip',
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'urbanisme.zip'
          link.click()
          this.isDownloading = false
          this.toast('Document Urbanisme', 'Téléchargement terminé avec succès')
        })
        .catch((error) => {
          this.toast(
            'Document Urbanisme',
            'Une erreur est survenue, veuillez réessayer',
            'warning',
          )
          this.isDownloading = false
        })
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off('load', this.loadDataToMap)
      this.removeMarkers([...this.markers_zone, ...this.markers_libelle_zone])
    }
  },
}
</script>
<style lang="scss">
.libelle-zone {
  font-weight: bold;
  font-size: 16px !important;
  -webkit-text-fill-color: white !important;
  -webkit-text-stroke-width: 0.1px !important;
  -webkit-text-stroke-color: white !important;
}
#urba_docs_content::-webkit-scrollbar {
  width: 0.4em;
}

#urba_docs_content::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #6c6c6c;
}

#urba_docs_content::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #c0c0c0;
}
</style>
